import React from "react";
import { PrimaryIcon } from "../components/styles";
import { isDataWorkloadsOneDotTwoDotTwoFeaturesEnabled, noop } from "@util";
import { DataLakeDataSet, ExecuteWorkloadEvent, SummaryViewData, WorkloadInstance, WorkloadInstanceState } from "@data";
import { useGetWorkloadInstance, useGetWorkloadInstanceLogs } from "@hooks";
import WorkloadInstanceSummaryView from "../components/SummaryView";
import LogsView from "../components/LogsView";
import {
  ActionMenuItem,
  CompletionMetadataView,
  DetailsView,
  DetailsViewActions,
  DetailsViewModel,
  DetailsViewRoute,
  WorkloadCancelExecutionDialog,
} from "@components";
import {
  CANCEL_EXECUTION,
  ExecutionInstanceAction,
  RERUN_EXECUTION
} from "@modules/dataWorkloadDetails/components/ExecutionInstancesView";
import TestViewDrawer from "@modules/dataWorkloadDetails/containers/TestViewDrawer";

interface ContainerModel extends DetailsViewModel {
  name: string;
  version: number;
  executionId: string;
  cancelExecutionEnabled?: boolean;
}

interface ContainerActions extends DetailsViewActions {
  showExecutionHistory: (name: string, version: number) => void;
  showWorkloadExecutionDetails: (name: string, version: number, executionId: string) => void;
}

type Props = ContainerModel & ContainerActions;

const WorkloadInstanceDetailsContainer = (props: Props) => {

  const {
    name,
    version,
    executionId,
    cancelExecutionEnabled = isDataWorkloadsOneDotTwoDotTwoFeaturesEnabled(),
    showExecutionHistory,
    showWorkloadExecutionDetails,
    ...otherProps
  } = props;

  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const [showTestView, setShowTestView] = React.useState(false);

  const [model, {refresh: refreshInstance}] =
    useGetWorkloadInstance({name, version, executionId});

  const [logModel, {refresh: refreshLogs, ...logActions}] =
    useGetWorkloadInstanceLogs({name, version, executionId});

  const refresh = React.useCallback(() => {
    refreshInstance();
    refreshLogs();
  }, [refreshInstance, refreshLogs]);

  const {workloadInstance = WorkloadInstance.EMPTY, ...otherModel} = model;

  const summaryViewItems = React.useMemo(() => [
    new SummaryViewData({
      className: "executionId",
      name: "Execution ID",
      value: workloadInstance.getExecutionId(),
    }),
    new SummaryViewData({
      className: "workloadName",
      name: "Workload Name",
      value: workloadInstance.getName(),
    }),
    new SummaryViewData({
      className: "createdBy",
      name: "Created By",
      value: workloadInstance.getCreatedBy(),
    }),
    new SummaryViewData({
      className: "executedBy",
      name: "Executed By",
      value: workloadInstance.getExecutedBy(),
    }),
    new SummaryViewData({
      className: "message",
      name: "Message",
      value: workloadInstance.getMessage(),
    }),
    new SummaryViewData({
      className: "state",
      name: "State",
      value: workloadInstance.getState(),
    }),
    new SummaryViewData({
      className: "executionDuration",
      name: "Execution Duration (ms)",
      value: workloadInstance.getExecutionDuration() > 0 ? workloadInstance.getExecutionDuration().toString() : "",
    }),
    new SummaryViewData({
      className: "executionDuration",
      name: "Data Scanned",
      value: workloadInstance.getDataScanned() > 0 ? workloadInstance.getDataScanned().toString() : "",
    }),
    new SummaryViewData({
      className: "createdAt",
      name: "Created At",
      value: workloadInstance.getState() === WorkloadInstanceState.QUEUED ? workloadInstance.getCreatedAt() : "",
      date: true,
    }),
    new SummaryViewData({
      className: "startedAt",
      name: "Started At",
      value: workloadInstance.getState() === WorkloadInstanceState.RUNNING ? workloadInstance.getSubmittedAt() : "",
      date: true,
    }),
    new SummaryViewData({
      className: "completedAt",
      name: "Completed At",
      value: workloadInstance.getCompletedAt(),
      date: true,
    }),
  ], [workloadInstance]);

  const triggerInfoItems = React.useMemo(() => [
    new SummaryViewData({
      className: "triggerType",
      name: "Type",
      value: workloadInstance.getEventType(),
    }),
    new SummaryViewData({
      className: "executionScope",
      name: "Execution Scope",
      value: workloadInstance.getExecutionScope(),
    }),
    new SummaryViewData({
      className: "listFiles",
      name: "List Files",
      value: workloadInstance.getListFiles(),
    }),
    new SummaryViewData({
      className: "scheduleId",
      name: "Schedule ID",
      value: workloadInstance.getScheduleId(),
    }),
    new SummaryViewData({
      className: "bucketName",
      name: "Bucket Name",
      value: workloadInstance.getEventBucketName(),
    }),
    new SummaryViewData({
      className: "storageKey",
      name: "Key",
      value: workloadInstance.getEventKey(),
    }),
    new SummaryViewData({
      className: "accountId",
      name: "Account ID",
      value: workloadInstance.getEventAccountId(),
    }),
    new SummaryViewData({
      className: "datasetAlias",
      name: "Data Set Alias",
      value: workloadInstance.getEventDataSetAlias(),
    }),
    new SummaryViewData({
      className: "precedingWorkloadId",
      name: "Preceding Workload",
      value: workloadInstance.getEventPrecedingWorkload(),
    }),
  ], [workloadInstance]);

  const autoRefreshEnabled = React.useMemo(() =>
    workloadInstance.getState() === WorkloadInstanceState.QUEUED ||
    workloadInstance.getState() === WorkloadInstanceState.RUNNING
    , [workloadInstance]);

  const SummaryView = React.useMemo(() => () =>
      (
        <WorkloadInstanceSummaryView
          summaryViewItems={summaryViewItems}
          triggerInfoItems={triggerInfoItems}
        />
      )
    , [summaryViewItems, triggerInfoItems]);

  const logsView = React.useMemo<() => React.ReactElement>(() => () => {
    return <LogsView {...logModel} {...logActions}/>;
  }, [logModel, logActions]);

  const completionMetadataView = React.useMemo<() => React.ReactElement>(() => () => {
    return (
      <CompletionMetadataView
        actions={workloadInstance.getWorkloadCompletionMetadataActions()}
        instanceVariables={workloadInstance.getWorkloadCompletionMetadataVariables()}
        renderInstanceVariables={true}
      />
    );
  }, [
    workloadInstance,
  ]);

  const logsAvailable = React.useMemo(() => workloadInstance.getState() === WorkloadInstanceState.SUCCEEDED ||
    workloadInstance.getState() === WorkloadInstanceState.FAILED ||
    workloadInstance.getState() === WorkloadInstanceState.RUNNING, [workloadInstance]);

  const routes: DetailsViewRoute[] = React.useMemo(() => [
    {
      id: "summary",
      name: "Summary",
      view: SummaryView,
    },
    {
      id: "completionMetadata",
      name: "Completion Metadata",
      path: "/completion-metadata",
      view: completionMetadataView,
    },
    {
      id: "logs",
      name: "Logs",
      path: "/logs",
      view: logsView,
      disabled: !logsAvailable,
      hidden: workloadInstance.isQueryType(),
    },
  ], [workloadInstance, SummaryView, logsView, completionMetadataView, logsAvailable]);

  const workloadInstanceIsQueuedOrPending = React.useMemo(() =>
      workloadInstance.getState() === WorkloadInstanceState.QUEUED ||
      workloadInstance.getState() === WorkloadInstanceState.PENDING,
    [workloadInstance]);

  const onClickAction = React.useCallback((action: ActionMenuItem) => {
    switch (action.id) {
      case ExecutionInstanceAction.CANCEL:
        return setShowCancelDialog(true);
      case ExecutionInstanceAction.RERUN:
        return setShowTestView(true);
        default:
          return noop();
    }
  }, [setShowCancelDialog, setShowTestView]);

  const actions = React.useMemo(() => {
    return cancelExecutionEnabled ? [
      {
        ...CANCEL_EXECUTION,
        disabled: !workloadInstanceIsQueuedOrPending,
      },
      {
        ...RERUN_EXECUTION,
        disabled: !workloadInstance.isFinished(),
      }
    ] : [];
  }, [workloadInstanceIsQueuedOrPending, workloadInstance, cancelExecutionEnabled]);

  const dataSets = React.useMemo(() =>
    workloadInstance.getDataSources().map(set => new DataLakeDataSet({ ...set })
  ), [workloadInstance]);

  const showExecutionHistoryView = React.useCallback(() => {
    showExecutionHistory(name, version);
  }, [name, version, showExecutionHistory]);

  const instanceEvent = React.useMemo(() => {
    return new ExecuteWorkloadEvent({
      ...workloadInstance.getEvent(),
      type: workloadInstance.getEventType(),
      "@type": workloadInstance.getEventType(),
      triggerStorage: workloadInstance.getEventTriggerStorage(),
      executionScope: workloadInstance.getExecutionScope(),
    });
  }, [workloadInstance]);

  return (
    <React.Fragment>
      <DetailsView
        {...otherModel}
        {...otherProps}
        refresh={refresh}
        className={"workloadInstanceDetails"}
        icon={PrimaryIcon}
        title={workloadInstance.getName() + " " + workloadInstance.getCreatedAt()}
        routes={routes}
        tabsMarginTop={false}
        autoRefresh={autoRefreshEnabled}
        actions={actions}
        onClickAction={onClickAction}
      />
      {showCancelDialog && (
        <WorkloadCancelExecutionDialog
          open={showCancelDialog}
          name={name}
          version={version}
          executionId={executionId}
          close={() => setShowCancelDialog(false)}
          onSuccessMessageShown={refreshInstance}
        />
      )}
      {showTestView && (
        <TestViewDrawer
          name={name}
          version={version}
          dataSets={dataSets}
          showTestView={showTestView}
          setShowTestView={setShowTestView}
          testTrigger={instanceEvent}
          isFunctionWorkload={workloadInstance.isFunctionType()}
          showExecutionHistoryView={showExecutionHistoryView}
          showExecutionInstanceDetails={showWorkloadExecutionDetails}
        />
      )}
    </React.Fragment>
  );
};

export default WorkloadInstanceDetailsContainer;
